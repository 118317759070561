import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ContentWrapper } from '../../global.css';
import StoreCtaMinimalSection from '../storeCtaMinimalSection';
import { Container, Title, Section, SectionTitle, FaqSection } from './faqPage.css';
import ExpandableList from '../core/expandableList';

const query = graphql`
  query {
    faqJson {
      title
      sections {
        title
        questions {
          question
          answer
        }
      }
    }
  }
`;

const FaqPage = () => {
  const data = useStaticQuery(query).faqJson;

  return (
    <Container>
      <FaqSection>
        <ContentWrapper padding={30}>
          <Title>{data.title}</Title>
          {data.sections.map(section => (
            <Section key={section.title}>
              <SectionTitle>{section.title}</SectionTitle>
              <ExpandableList data={section.questions} />
            </Section>
          ))}
        </ContentWrapper>
      </FaqSection>
      <StoreCtaMinimalSection />
    </Container>
  );
};

export default FaqPage;
