import React from 'react';
import Layout from 'components/layout';
import FaqPage from '../components/faqPage';

const Index = () => {
  return (
    <Layout>
      <FaqPage />
    </Layout>
  );
};

export default Index;
