import styled from 'styled-components';
import MEDIA from '../../helpers/mediaTemplates';

export const Container = styled.div`
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100%;
  padding-top: 138px;

  ${MEDIA.MIN_TABLET`
    padding-top: 242px;
  `};
`;

export const FaqSection = styled.section`
  width: 100%;
  padding-bottom: 80px;

  ${MEDIA.MIN_TABLET`
    padding-bottom: 120px;
  `};
`;

export const Title = styled.h1`
  white-space: pre-line;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 40px;

  ${MEDIA.MIN_TABLET`
    margin-bottom: 90px;
    font-size: 40px;
    line-height: 56px;
  `};
`;

export const Section = styled.section`
  &:not(:last-child) {
    margin-bottom: 90px;
  }
`;

export const SectionTitle = styled.h2`
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 10px;
`;
